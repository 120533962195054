<template src="./RegisterWithToken.html"></template>

<script>
import { useStore } from "@/store/store.js"
import mixins from "@/mixins/mixins.js";
import WholeMultiselect from "@/components/whole-multiselect/WholeMultiselect.vue";
export default {
  mixins: [mixins],
  components: { WholeMultiselect },
  data() {
    return {
      invalidFields: {
        name: false,
        email: false,
        gender: false,
        dateBirth: false,
        rg: false,
        cpf: false,
        phone: false,
        zipcode: false,
        city: false,
        state: false,
        street: false,
        number: false,
        password: false,
        password2: false,
      },
      titleCard: "Cadastrar novo Usuário",
      genderOptions: [
        { text: "Feminino", value: "F" },
        { text: "Masculino", value: "M" },
      ],
      password: "",
      password2: "",
      weakPasswordWarning: false,
      weakPassword:
        "A senha deve ter ao menos 8 caracteres, letras maiúsculas, minúsculas, números e caractere especial (@!#$%).",
      useTerms: false,
      termsAlertControl: false,
      name: null,
      email: null,
      gender: null,
      dateBirth: null,
      rg: null,
      cpf: null,
      phone: null,
      address: {
        id: "",
        zipcode: "",
        city: "",
        complement: "",
        state: "",
        street: "",
        number: "",
      },
      disable: {
        city: true,
        state: true,
        street: false,
      },
      saveClicked: false,
      oscs: [],
      osc: null,
      token: null,
      specificFieldsMessage: {
        email: "Campo obrigatório",
        phone: "Campo obrigatório",
        birthdate: "Campo obrigatório",
        cep: "CEP inválido",
        password1: "Campo obrigatório",
        password2: "Campo obrigatório",
      },
    };
  },
  watch: {
    name: function () {
      const vm = this;
      vm.invalidFields.name =
        vm.testNullUndefinedEmpty(vm.name) && vm.saveClicked;
    },
    cpf: function () {
      const vm = this;
      vm.invalidFields.cpf = !vm.validateCPF(vm.cpf) && vm.saveClicked;
    },
    rg: function () {
      const vm = this;
      vm.invalidFields.rg = vm.testNullUndefinedEmpty(vm.rg) && vm.saveClicked;
    },
    gender: function () {
      const vm = this;
      vm.invalidFields.gender =
        vm.testNullUndefinedEmpty(vm.gender) && vm.saveClicked;
    },
    phone: function () {
      const vm = this;
      vm.invalidFields.phone =
        vm.testNullUndefinedEmpty(vm.phone) && vm.saveClicked;
    },
    dateBirth: function () {
      const vm = this;
      vm.invalidFields.dateBirth =
        !vm.dateIsValidAndBeforeNow(vm.dateBirth) && vm.saveClicked;
    },
    email: function () {
      const vm = this;
      vm.invalidFields.email = !vm.validateEmail(vm.email) && vm.saveClicked;
    },
    "address.street": function () {
      const vm = this;
      vm.invalidFields.street =
        vm.testNullUndefinedEmpty(vm.address.street) && vm.saveClicked;
    },
    "address.number": function () {
      const vm = this;
      vm.invalidFields.number =
        vm.testNullUndefinedEmpty(vm.address.number) && vm.saveClicked;
    },
    "address.city": function () {
      const vm = this;
      vm.invalidFields.city =
        vm.testNullUndefinedEmpty(vm.address.city) && vm.saveClicked;
    },
    "address.state": function () {
      const vm = this;
      vm.invalidFields.state =
        vm.testNullUndefinedEmpty(vm.address.state) && vm.saveClicked;
    },
  },
  computed: {
    isProject() {
      return false;
    },
  },
  setup () {
    const store = useStore()
    return {
      getAddressByZipcode: (cep) => store.dispatch("getAddressByZipcode", cep),
    }
  },
  mounted: function () {
    this.token = this.$route.params.token;
  },
  methods: {
    async getAddressData(zipcode) {
      this.invalidFields.zipcode = !this.validateZipCode(zipcode)
      
      if (!this.invalidFields.zipcode) {
        const response = await this.getAddressByZipcode(zipcode)
        this.invalidFields.zipcode = !response || !response.address

        if (!this.invalidFields.zipcode) {
          this.address.city = response.address.city
          this.address.state = response.address.state
          this.address.street = response.address.street
          this.disable.street = !!this.address.street
        }
      }
    },
    validateFields() {
      const vm = this;
      let isInvalid = false;
      vm.saveClicked = true;

      vm.invalidFields.email = !vm.validateEmail(vm.email);
      vm.invalidFields.name = vm.testNullUndefinedEmpty(vm.name);
      vm.invalidFields.gender = vm.testNullUndefinedEmpty(vm.gender);
      vm.invalidFields.dateBirth = !vm.dateIsValidAndBeforeNow(vm.dateBirth);
      vm.invalidFields.phone = vm.testNullUndefinedEmpty(vm.phone);
      vm.invalidFields.zipcode =
        vm.invalidFields.zipcode || !vm.validateZipCode(vm.address.zipcode);
      vm.invalidFields.rg = vm.testNullUndefinedEmpty(vm.rg);
      vm.invalidFields.cpf = !vm.validateCPF(vm.cpf);
      vm.invalidFields.street = vm.testNullUndefinedEmpty(vm.address.street);
      vm.invalidFields.number = vm.testNullUndefinedEmpty(vm.address.number);
      vm.invalidFields.city = vm.testNullUndefinedEmpty(vm.address.city);
      vm.invalidFields.state = vm.testNullUndefinedEmpty(vm.address.state);
      vm.invalidFields.password = vm.testNullUndefinedEmpty(vm.password);

      isInvalid =
        vm.invalidFields.email ||
        vm.invalidFields.name ||
        vm.invalidFields.gender ||
        vm.invalidFields.dateBirth ||
        vm.invalidFields.phone ||
        vm.invalidFields.rg ||
        vm.invalidFields.cpf ||
        vm.invalidFields.zipcode ||
        vm.invalidFields.street ||
        vm.invalidFields.number ||
        vm.invalidFields.city ||
        vm.invalidFields.state ||
        vm.invalidFields.password;

      if (isInvalid) {
        vm.$vs.notify({
          title: "Atenção",
          text: "Campos obrigatórios não preenchidos ou inválidos",
          color: "warning",
          position: "top-right",
        });
      } else {
        vm.postEmployee();
      }
    },

    postEmployee() {
      const vm = this;
      const employee = {
        name: vm.name,
        email: vm.email,
        phone: vm.phone.replace(/[^\d]+/g, ""),
        gender: vm.gender.value,
        rg: vm.rg.replace(/[^\d]+/g, ""),
        cpf: vm.cpf.replace(/[^\d]+/g, ""),
        address: vm.address,
        birthdate: vm.dateBirth,
        b2b: true,
        osc: vm.osc,
        token: vm.token,
        password: vm.password,
      };
      vm.axios
        .post(
          vm.$store.state.filooServer + "acess-by-token/employees",
          employee
        )
        .then(function (response) {
          if (response.status == 200) {
            vm.$swal({
              title: "Sucesso!",
              text: "Dados registrados com sucesso.",
              icon: "success",
            });
            vm.$router.push({ name: "login" });
          }
        })
        .catch(function (error) {
          if (error.response.status === 409) {
            vm.$swal({
              title: "Atenção!",
              text: error.response.data.message,
              icon: "error",
            });
          }
          if (error.response.status === 500) {
            vm.$swal({
              title: "Atenção!",
              text: "Não foi possível realizar esta operação contacte o administrador do sistema.",
              icon: "error",
            });
          }
        });
    },
    passwordVisibility() {
      const vm = this;
      if (vm.passwordType === "password") {
        vm.passwordType = "text";
      } else {
        vm.passwordType = "password";
      }
    },
    testPasswordStrength(password) {
      const vm = this;
      const regex =
        /(?=^.{8,}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
      if (password == "") return;
      if (regex.test(password)) {
        vm.invalidFields.password = false;
      } else {
        vm.specificFieldsMessage.password1 = vm.weakPassword;
        vm.invalidFields.password = true;
      }
    },
  },
};
</script>

<style lang="scss" src="./RegisterWithToken.scss"></style>
